<template>
    <a-drawer
        class="drawer"    
        :headerStyle="{ display: 'none' }"
        :wrapStyle="{ borderTopLeftRadius: '30px'}"

        title="Регистрация"
        :visible="registrationDrawerVisible"
        @close="registrationDrawerVisible = false"
        :zIndex="1000"
        :width="drawerWidth"
        placement="right">
        <div class="flex flex-col h-full">
            <header class="drawer_header flex justify-between items-center">
                <span class="drawer_title">{{ isThanks ? '' : 'Регистрация'}}</span>
                <div
                    @click="closeRegistrationDrawer" 
                    class="close_icon"></div>
            </header>
            <div class="drawer_body">
                <template v-if="!isThanks">
                    <div class="steps flex justify-between">
                        <div class="step_title">
                            <div class="blue_color text-lg mb-2">
                                {{ stepTitle }}
                            </div>
                            <p class="step_description">
                                {{ stepDescription }}
                            </p>
                        </div>
                        <Steps 
                            class="step_list"
                            showDecimal
                            highlightPrevious
                            :current="regStep"
                            :steps="[0, 1, 2]" />
                    </div>
                </template>
                <Page />
            </div>

        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'

import Page from './Registration/Page.vue'
import Steps from '@/components/Steps'
export default {
    components: {
        Page,
        Steps
    },
    data() {
        return {
            registrationDrawerVisible: false,
            steps: [
                {
                    title: 'Информация об организации',
                    description: 'Введите БИН вашей организации и мы автоматически подгрузим информацию о названии и иерархической структуре подчинённости',
                },
                {
                    title: 'Верификация',
                    description: 'Подтвердите свою личность для защиты аккаунта. На указанный адрес электронной почты будет отправлено сообщение, содержащее код подтверждения. Введите его ниже, чтобы закончить регистрацию',
                },
                {
                    title: 'Личная информация',
                    description: 'Пожалуйста, укажите Ваше имя и фамилию. Чтобы облегчить коммуникацию с коллегами укажите свою должность в организации',
                },
            ]

        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        drawerWidth() {
            if (this.windowWidth > 1024) 
                return 936
            if (this.windowWidth > 828)
                return 828
            return this.windowWidth
        },
        regStep() {
            return this.$store.state.user.regStep
        },
        currentStep() {
            return this.steps.find((step, index) => this.regStep-1 === index)
        },
        stepRules() {
            if (this.currentStep)
                return this.currentStep.rules
            return []
        },
        isThanks() {
            return this.regStep === 4
        },
        stepTitle() {
            if (this.currentStep)
                return this.currentStep.title
            return []
        },
        stepDescription() {
            if (this.currentStep)
                return this.currentStep.description
            return []
        },
    },
    mounted() {
        eventBus.$on('open_registration', () => {
            this.openRegistrationDrawer()
        })
        eventBus.$on('close_registration', () => {
            this.closeRegistrationDrawer()
        })
    },
    beforeDestroy() {
        eventBus.$off('open_registration')
        eventBus.$off('close_registration')
    },
    methods: {
        closeRegistrationDrawer() {
            this.registrationDrawerVisible = false
        },
        openRegistrationDrawer() {
            this.registrationDrawerVisible = true
        },
    }


}
</script>

<style lang="scss" scoped>
$breakpoint-1: 828px;
.drawer_header {
    margin-bottom: 30px;
}
.drawer_title {
    font-size: 32px;
    color: #0C0C0E;
}
.close_icon {
    width: 20px;
    height: 20px;

    background-size: contain;
    background-image: url('~@/assets/svg/cross_in_round.svg');
    
    cursor: pointer;
    &:hover {
        background-image: url('~@/assets/svg/cross_in_round_active.svg');
    }
}

.steps {
    margin-bottom: 30px;
    @media (max-width: $breakpoint-1) {
        flex-direction: column-reverse;
    }
}
.step_list {
    @media (max-width: $breakpoint-1) {
        margin-bottom: 30px;
    }
}
.step_title {
    margin-right: 32px;
    @media (max-width: $breakpoint-1) {
        margin-right: 0;;
    }
}
.step_description {
    font-size: 14px;
    line-height: 1.4em;
    color: #000000D9;
}
.drawer::v-deep {
    // font-family: 'Rubik', sans-serif;

    .drawer_body {
        padding-bottom: 40px;
    }
    .ant-drawer-content {
        border-radius: 30px 0px 0px 30px;
        @media (max-width: 828px) {
            border-radius: 0;
        }
    }
    .ant-drawer-body {
        height: 100%;
        padding: 50px;
        padding-right: 160px;
        @media (max-width: 1024px) {
            padding: 50px;
            padding-left: 70px;
            padding-right: 30px;
        }
        @media (max-width: 828px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
</style>